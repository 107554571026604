@import "@/styles/_variables.scss";
    @import "@/styles/_mixins.scss";
    @import "@/styles/_variables.scss";
  
.tabList {
  width: 100%;
  align-items: center;
  padding: 10px 12px !important;
  // padding: 16px !important;
  border-bottom: 1px solid rgba(255, 255, 255, 0.15) !important;
  position: relative;
  height: 52px !important;
  min-height: 52px !important;
  button {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    border-color: transparent !important;
    padding: 0px !important;
  }

  :global {
    .chakra-popover__popper {
      position: absolute;
      top: 0 !important;
      left: 0 !important;
      transform: unset !important;
      width: 100%;
      .chakra-popover__content {
        width: 100%;
        border: none !important;
      }
    }
  }
}

.tabPanels {
  height: calc(100% - 52px) !important;
}
