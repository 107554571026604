@import "@/styles/_variables.scss";
    @import "@/styles/_mixins.scss";
    @import "@/styles/_variables.scss";
  
.countdown {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
  width: 100%;

  svg,
  path {
    fill: #fff !important;
  }
}

.text {
  color: #fff;
  font-family: $font-alp;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  min-width: 36px;
  text-align: left;
}
