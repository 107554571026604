@import "@/styles/_variables.scss";
    @import "@/styles/_mixins.scss";
    @import "@/styles/_variables.scss";
  
.container {
  flex-direction: column;
  height: 100%;

  .createModalBg {
    background: var(--alpha-createModalBg);
  }

  .text {
    color: var(--alpha-createModalText);
  }
}

.modalContent {
  padding: 20px !important;
}

.tabList {
  background: #000;
  padding: 0px 16px;
  position: sticky !important;
  // z-index: 9999;
  top: 0;
  gap: 2px !important;
  // height: 52px !important;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1) !important;
}

.tab {
  display: flex;
  gap: 4px;
  align-items: center;
  // opacity: 1 !important;
  // padding: 12px 10px !important;
  // border-radius: 100px;
  transition: all 0.1s ease;
  min-height: 32px;
  height: 32px;
  position: relative;
  border: none !important;
  outline: none !important;
  border-color: none !important;

  span {
    color: rgba(255, 255, 255, 0.4);
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: -0.28px;
    // text-transform: uppercase;
  }

  svg {
    width: 20px;
    height: 20px;

    path {
      // fill: #000;
    }
  }

  &[aria-selected='true'] {
    border: none;
    outline: none;

    span {
      color: #fff;
    }

    ::after {
      position: absolute;
      content: '';
      width: 24px;
      height: 2px;
      bottom: 0px;
      left: 50%;
      transform: translateX(-50%);
      border-bottom: 2px solid #ffffff;
      user-select: none;
      transition: all 200ms;
    }
    // background: #fff;
  }
}

.tabPanels {
  background-color: #000;
  padding-top: 12px;
  .tabPanel {
    height: calc(100vh - 67px - 65px - 50px - env(safe-area-inset-top));
  }

  &.ios {
    .tabPanel {
      height: calc(
        100vh - 73px - 16px - 65px - 50px - env(safe-area-inset-top)
      );
    }
  }
}

.btnText {
  position: absolute;
  background-color: #f8f0ac;
  border-radius: 100px;
  padding: 5px 12px;
  color: #000 !important;
  min-width: 93px;
  top: 10px;
  right: 20px;
  text-align: center;
}
