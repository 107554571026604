@import "@/styles/_variables.scss";
    @import "@/styles/_mixins.scss";
    @import "@/styles/_variables.scss";
  
.myItemContainer {
  background: #ffffff1a;
  border-top: 0 !important;
  .title {
    color: #fff;
    opacity: 0.7;
    font-size: 12px;
    font-weight: 400;
    line-height: 12px;
    letter-spacing: 0em;
  }
  .blockItem {
    flex: 1;
    border-radius: 8px;
    padding: 0px 12px;
    gap: 6px;
    flex-direction: column;
    .value {
      color: #fff;
      align-items: flex-start;
      justify-content: flex-start;

      font-size: 14px;
      font-weight: 600;
      line-height: 20px;
      letter-spacing: -0.01em;

      p {
        color: #fff;
        align-items: flex-start;
        justify-content: flex-start;

        font-size: 14px;
        font-weight: 600;
        line-height: 20px;
        letter-spacing: -0.01em;
      }
      span {
        opacity: 0.7;
      }
    }
  }
}
