@import "@/styles/_variables.scss";
    @import "@/styles/_mixins.scss";
    @import "@/styles/_variables.scss";
  
.modalContent {
  padding: 0 !important;
  :global {
    .modal-header-wrap {

    }
    .chakra-modal__header {
      font-size: 20px !important;
      font-weight: 600;
      //padding: 20px 0 0px;
      text-transform: unset !important;
      text-align: left;
    }
  }
}
