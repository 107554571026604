@import "@/styles/_variables.scss";
    @import "@/styles/_mixins.scss";
    @import "@/styles/_variables.scss";
  
.listContainer {
  :global {
    .chakra-table {
      table-layout: fixed;
      thead {
        tr {
          th {
            padding-bottom: 16px;
            &:last-child {
              align-items: flex-end;
              justify-content: flex-end;
              display: flex;
            }
            & > div {
              justify-content: flex-end;
            }
            &:first-child {
              & > div {
                justify-content: flex-start;
              }
            }
          }
        }
      }
      tbody {
        tr {
          td {
            border-bottom: none;
            padding-top: 16px;
            padding-bottom: 16px;
            &:first-child {
              padding-left: 12px;
            }
          }
        }
      }
    }
  }
  .btnBuy {
    height: 36px;
    padding: 0;
    flex: 1;
  }
}

.buyer {
  color: var(--alpha-text) !important;
  line-height: 20px;
  max-height: 20px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  // line-height: 100%;
  font-weight: 500;
  font-size: 14px;
}
.desc {
  font-size: 12px;
  opacity: 0.7;
}

.itemContainer {
  padding: 12px;
  &:first-child {
    border-top: 1px solid rgba(255, 255, 255, 0.15);
    td {
      padding-top: 20px !important;
    }
  }

  .icon {
    width: 16px;
    svg {
      path {
        fill: #fff;
      }
    }
  }
}

.coinContainer {
  flex-direction: column;
  height: 100%;
  .percent {
    width: 70px;
    height: 32px;
    border-radius: 4px;
    align-items: center;
    justify-content: center;
    float: right;
    &.hight {
      background: linear-gradient(180deg, #a3ffd3 0%, #4cfea6 100%);
    }
    &.low {
      background: linear-gradient(180deg, #ffa3a3 0%, #fe4c4c 100%);
    }
    p {
      font-size: 13px;
      font-weight: 500;
      line-height: 18px;
      letter-spacing: 0em;
      color: #000 !important;
    }
  }
}

.footer {
  width: 100%;
  min-height: 60px;
  height: 60px;
  background: var(--alpha-navBg);
  box-shadow: 0px 0px 24px -6px rgba(0, 0, 0, 0.12);
  align-items: center;
  justify-content: space-between;
  padding: 0 12px;
  gap: 12px;
  .btnAction {
    height: 40px !important;
    width: 100%;
    // width: 68px !important;
    padding: 1rem 2rem !important;
    //max-width: 70px;
  }

  .btnBuy {
    &.isDisabled {
      background-color: #f8f0ac;
      opacity: 1;
    }

    &.isNotAllowBuy {
      background-color: #f8f0ac;
      padding-top: 18px;
      padding-bottom: 18px;
    }

    p {
      font-weight: 500;
      font-size: 14px;
      line-height: 120%;
      letter-spacing: 0.01em;

      /* Color-newsystem/White/100 */
      color: var(--alpha-btn-text) !important;
    }
  }
  .errorText {
    font-size: rem(11);
    text-align: center;
    margin-top: 1px;
    color: #ff6868;
    font-weight: 500;
  }
}

.modalItem {
  width: 100%;
}

.itemModalContainer {
  margin-bottom: 16px;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
}
