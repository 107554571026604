@import "@/styles/_variables.scss";
    @import "@/styles/_mixins.scss";
    @import "@/styles/_variables.scss";
  
.modalContainer {
  border: 1.5px solid #000000;
  overflow: hidden;
  padding: 0 !important;
  .modalBtnClose {
    svg {
      width: 10px;
      height: 10px;
    }
  }
  .modalTitle {
    // background-color: #000000;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    // text-align: center;
    font-size: 16px;
    padding: 16px 20px;
  }
  .portfolioValue {
    font-size: 42px;
    line-height: 42px;
    font-weight: 600;
  }
  .portfolioLabel {
    margin: 8px 0px;
    font-size: 14px;
    opacity: 0.8;
  }
  .modalFooter {
    background: linear-gradient(90deg, #00f5a0 0%, #00d9f5 100%);
    margin-bottom: 0;
    justify-content: center;
    flex-direction: column;
    gap: 10px;
    .btnShare {
      padding: 15px 30px !important;
      font-size: 14px !important;
      font-weight: 500;
      margin-bottom: 8px;
      height: 40px;
      background-color: #fff;
      color: #000000;
      gap: 4px;
      svg {
        background-color: #000;
        border-radius: 50%;
        width: 20px;
        height: 20px;
        path {
          fill: #fff;
        }
      }
    }
    .pointValue {
      color: #000000;
      font-weight: 400;
      font-size: 14px;
      b {
        font-weight: 700;
        font-size: 16px;
      }
    }
  }
}
