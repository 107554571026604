@import "@/styles/_variables.scss";
    @import "@/styles/_mixins.scss";
    @import "@/styles/_variables.scss";
  
.wrapper {
  * {
    font-family: $font-alp;
  }
}

.block_wrapper {
  border-radius: 8px;
  background: var(--alpha-listMenu);
  text-align: left;

  h5 {
    font-size: 14px;
    font-weight: 600;
    line-height: 160%; /* 22.4px */
    margin-bottom: 8px;
  }
}

.keyWrapper {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 16px 20px;
  gap: 2px;
  border-radius: 8px;
  background-color: #cbc7c7;
  width: 100%;
}

.tips_wrapper {
  padding: 8px 12px;
  border-radius: 8px;
  background: var(--alpha-tipsWrapperBg);

  h6 {
    font-weight: 600;
    font-size: 12px;
  }

  li {
    color: var(--alpha-tipsDesc);
    font-size: 12px;
    list-style: disc;
    margin-left: 16px;
    line-height: 160%;
  }
}

.input {
  border-radius: 8px;
  border: 1px solid var(--alpha-confirmBorder);
  background: var(--alpha-confirmBg);
  padding: 12px;
  color: var(--alpha-confirmText);
  font-size: 12px;
  font-weight: 500;
  outline: none;
  width: 100%;
}

.confirm_btn {
  margin-top: 16px;
  &:disabled {
    opacity: 0.5;
  }
}

.description {
  text-align: left;
  color: var(--alpha-tipsDesc);
  font-size: 12px;
  font-weight: 400;
  line-height: 160%; /* 19.2px */
  margin-bottom: 8px;
}

.keySet {
  font-size: 16px;
  line-height: 1.4;
  line-break: anywhere;
  text-align: left;
  font-weight: 500;
}

.qrWrapper {
  display: flex;
  justify-content: center;
}

.codeInput {
  > div:not(.error) {
    display: grid;
    grid-template-columns: repeat(8, 1fr);
    gap: 8px;
  }
  input {
    box-shadow: 0px 0px 12px -6px var(--alpha-secureWalletBorder);
    // background: linear-gradient(0deg, rgba(116, 200, 246, 0.4), rgba(116, 200, 246, 0.4)), linear-gradient(0deg, #FFFFFF, #FFFFFF);
    border: 1px solid var(--alpha-secureWalletBorder);
    // width: 60px !important;
    // height: 60px !important;
    width: 100% !important;
    aspect-ratio: 1/1;
    border-radius: 8px;
    font-weight: 600;
    font-size: 20px;
    line-height: 20px;
    color: var(--alpha-text);

    &::placeholder {
      color: rgba(255, 255, 255, 0.7);
    }
  }

  .error p {
    font-size: 12px;
    font-weight: 500;
    margin-top: 4px;
    color: #ff0000 !important;
  }
}
