@import "@/styles/_variables.scss";
    @import "@/styles/_mixins.scss";
    @import "@/styles/_variables.scss";
  
.listContainer {
  background-color: #000;

  :global {
    .chakra-table {
      table-layout: fixed;
      thead {
        tr {
          th {
            padding-bottom: 16px;
            &:last-child {
              align-items: flex-end;
              justify-content: flex-end;
              display: flex;
            }
            & > div {
              justify-content: flex-end;
            }
            &:first-child {
              & > div {
                justify-content: flex-start;
              }
            }
          }
        }
      }
      tbody {
        tr {
          td {
            border-bottom: none;
            padding-top: 12px;
            padding-bottom: 12px;
          }
        }
      }
    }
  }
}

.btnBuy {
  height: 36px !important;
  padding: 0 !important;
  flex: 1;
}

.itemContainer {
  padding: 12px;
  &:first-child {
    border-top: 1px solid rgba(255, 255, 255, 0.15);
    td {
      padding-top: 20px !important;
    }
  }
  .buyer {
    color: var(--alpha-text) !important;
    line-height: 20px;
    max-height: 20px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    // line-height: 100%;
    font-weight: 500;
    font-size: 14px;
  }
  .desc {
    font-size: 12px;
    opacity: 0.7;
  }
  .icon {
    width: 16px;
    svg {
      path {
        fill: #fff;
      }
    }
  }
}
